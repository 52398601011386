<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-row :gutter="24">
        <!-- 部门树 -->
        <a-col :span="6">
          <dept-tree ref="deptTree" :deptOptions="deptOptions" @select="clickDeptNode" />
        </a-col>
        <a-col :span="18">
          <div v-if="!isOpen1 && !isOpen2 && !isOpen3 && !isOpen4">
            <!-- 条件搜索 -->
            <div class="table-page-search-wrapper">
              <a-form layout="inline">
                <a-row :gutter="48">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="区域名称">
                      <a-input v-model="queryParam.name" placeholder="请输入" allow-clear/>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                      <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                      <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                    </span>
                  </a-col>
                </a-row>
              </a-form>
            </div>
            <!-- 操作 -->
            <div class="table-operations">
              <a-button type="primary" @click="$refs.createForm.handleAdd(1)" v-hasPermi="['wisdom:devicearea:add']">
                <a-icon type="plus" />新增
              </a-button>
              <!-- <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['wisdom:devicearea:edit']">
          <a-icon type="edit" />修改
        </a-button> -->
              <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['wisdom:devicearea:remove']">
                <a-icon type="delete" />删除
              </a-button>
              <table-setting
                :style="{float: 'right'}"
                :table-size.sync="tableSize"
                v-model="columns"
                :refresh-loading="loading"
                @refresh="getList" />
            </div>
            <!-- 增加修改 -->
            <create-form
              ref="createForm"
              @ok="getList"
            />
            <!-- 数据展示 -->
            <a-table
              :loading="loading"
              :size="tableSize"
              rowKey="id"
              :columns="columns"
              :data-source="list"
              :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
              :pagination="false"
              :bordered="tableBordered">
              <span slot="createTime" slot-scope="text, record">
                {{ parseTime(record.createTime) }}
              </span>
              <span slot="operation" slot-scope="text, record">
                <!-- <a @click="goSub(record)">
                  <a-icon type="eye" />下级
                </a> -->
                <!-- <a-divider type="vertical" v-hasPermi="['wisdom:devicearea:edit']"/>
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['wisdom:devicearea:edit']">
            <a-icon type="edit" />修改
          </a> -->
                <!-- <a-divider type="vertical" v-hasPermi="['wisdom:devicearea:remove']"/> -->
                <a @click="handleDelete(record)" v-hasPermi="['wisdom:devicearea:remove']">
                  <a-icon type="delete" />删除
                </a>
              </span>
            </a-table>
            <!-- 分页 -->
            <a-pagination
              class="ant-table-pagination"
              show-size-changer
              show-quick-jumper
              :current="queryParam.pageNum"
              :total="total"
              :page-size="queryParam.pageSize"
              :showTotal="total => `共 ${total} 条`"
              @showSizeChange="onShowSizeChange"
              @change="changeSize"
            />
          </div>
          <!-- 下级 -->
          <list1 ref="list1" v-if="isOpen1"/>
          <!-- 下级 -->
          <list2 ref="list2" v-if="isOpen2"/>
          <!-- 下级 -->
          <list3 ref="list3" v-if="isOpen3"/>
          <!-- 下级 -->
          <list4 ref="list4" v-if="isOpen4"/>
        </a-col>
      </a-row>
    </a-card>
  </page-header-wrapper>
</template>

<script>

import { areaPage, areaList, areaDel } from '@/api/pages/region'
import DeptTree from '@/components/DeptTree/DeptTree'
import CreateForm from './modules/CreateForm'
import list1 from './modules/list1'
import list2 from './modules/list2'
import list3 from './modules/list3'
import list4 from './modules/list4'
import { tableMixin } from '@/store/table-mixin'
export default {
  mixins: [tableMixin],
  name: 'Config',
  components: {
    CreateForm, DeptTree, list1, list2, list3, list4
  },
  data () {
    return {
      // 部门树选项
      deptOptions: [{
        id: 0,
        label: '',
        children: []
      }],
      isOpen1: false,
      isOpen2: false,
      isOpen3: false,
      isOpen4: false,
      dataList: [],
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      refreshing: false,
      total: 0,
      // 日期范围
      dateRange: [],
      queryParam: {
        parentId: 0,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '区域名称',
          dataIndex: 'areaName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],

      formTitle: null,
      form: {
        communityId: '',
        deviceAreaCityId: '',
        deviceAreaId: '',
        deviceAreaProvinceId: ''
      }
    }
  },
  filters: {
  },
  created () {
    this.getDeptTree()
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    this.$bus.$on('refresh', (data) => { // 绑定当前事件
      console.log('我是主组件，收到了数据', data)
      this.getDeptTree()
    })
  },
  beforeDestroy() { // 收尾操作，销毁
    this.$bus.$off('refresh') // $off解绑当前组件所用到的事件
  },
  methods: {
    goSub(record) {
      this.isOpen1 = true
      this.$refs.list1.getList(record)
    },
    /** 查询区域下拉树结构 */
    getDeptTree() {
      areaList().then(response => {
        this.deptOptions = response.data
      })
    },
    clickDeptNode(id, e) {
      const that = this
      console.log(id, e)
      const item = e.selectedNodes[0].data.props
      const label = e.selectedNodes[0].data.props.label
      const level = e.selectedNodes[0].data.props.level
      // this.level = level
      if (level === 1) {
        this.isOpen1 = true
        this.isOpen2 = false
        this.isOpen3 = false
        this.isOpen4 = false
        this.form.deviceAreaProvinceId = item.id
        this.formTitle = label
        setTimeout(() => {
          that.$refs.list1.getList(item)
        }, 500)
      } else if (level === 2) {
        // 获取父目录名称
        // const length = e.checkedNodesPositions.length // 获取选中的子目录存储位置长度
        //   const popArr = e.checkedNodesPositions[length - 1].pos.split('-') // 获取选中的子目录所在位置并切割成数组
        //   popArr.shift()
        //   console.log(this.getParentNodes(popArr, this.deptOptions))
        this.isOpen1 = false
        this.isOpen2 = true
        this.isOpen3 = false
        this.isOpen4 = false
        this.form.deviceAreaCityId = item.ids
        setTimeout(() => {
          that.$refs.list2.getList(item, [this.formTitle], that.form)
        }, 500)
      } else if (level === 3) {
        this.isOpen1 = false
        this.isOpen2 = false
        this.isOpen3 = true
        this.isOpen4 = false
        this.form.deviceAreaId = item.id
        setTimeout(() => {
          that.$refs.list3.getList(item, that.form)
        }, 500)
      } else if (level === 4) {
        this.isOpen1 = false
        this.isOpen2 = false
        this.isOpen3 = false
        this.isOpen4 = true
        this.form.communityId = item.id
        setTimeout(() => {
          that.$refs.list4.getList(item, that.form)
        }, 500)
      } else if (level === 5) {

      }
    },
    // 获取父目录  nodesArr为节点位置数组，treeData为目录树数据
    getParentNodes(nodesArr, treeData) { //
      let node = []
      let objParentNodes = '' // 存放父节点名称
      node = treeData[nodesArr[0]].ChildNodes[nodesArr[1]] // 这里的逻辑可根据需求写成循环获取，
      objParentNodes = node.text
      return objParentNodes
    },
    /** 查询参数列表 */
    getList () {
      this.loading = true
      areaPage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    filterOption (input, option) {
      // console.log(input, option)
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        parentId: 0,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const configIds = row.id ? [row.id] : this.ids
      this.$confirm({
        title: '提示',
        content: '确认删除所选中数据?',
        onOk () {
          // const arr = []
          // configIds.map(item => {
          //   arr.push({
          //     id: item
          //   })
          // })
          // return areaDel(arr)
          return areaDel(configIds)
            .then(() => {
              that.onSelectChange([], [])
              that.$bus.$emit('refresh', true)
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    }
  }
}
</script>
